import React from 'react';
import './public-event-browser-popup.scss';
import Button from '~/components/base/Button';
import { BottomSheet } from 'react-spring-bottom-sheet';
import Typography from '~/components/base/Typography/Typography';
import Avatar from '~/components/base/Avatar';

type Props = {
    className?: string;
    logo?: string;
    isOpenPopup: boolean;
    onOpenApp: () => void;
    isProcessingOpenApp: boolean;
    onContinueInBrowser: () => void;
    isFusiliers: boolean;
    isRoyalAnglianConnect: boolean;
};

export default function PublicEventBrowserPopup({
    className = '',
    logo,
    isOpenPopup,
    onOpenApp,
    isProcessingOpenApp,
    onContinueInBrowser,
    isFusiliers,
    isRoyalAnglianConnect
}: Props): JSX.Element {
    return (
        <BottomSheet open={isOpenPopup}>
            <div className={`public-event-browser-popup ${className}`}>
                {!!logo && (
                    <Avatar
                        size={72}
                        isCircled={true}
                        borderWidth={4}
                        borderColor='neutral-0'
                        className='public-event-browser-popup__thumbnail'
                        uri={logo} />
                )}
                <Typography
                    weight='bold'
                    color='neutral-500'
                    size={20}
                    className='public-event-browser-popup__title'>
                    Get the full experience on the app
                </Typography>
                <div className='public-event-browser-popup__button-container'>
                    <Button
                        variant='primary'
                        size='lg'
                        label={`Open in ${isFusiliers ? 'Fusiliers Connect' : isRoyalAnglianConnect ? 'Royal Anglian Connect' : 'Military'
                            } app`}
                        isProcessing={isProcessingOpenApp}
                        onClick={onOpenApp} />
                    <Button
                        variant='secondary'
                        size='lg'
                        label='Not now'
                        onClick={onContinueInBrowser} />
                </div>
            </div>
        </BottomSheet>
    );
}
